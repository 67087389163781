import React from "react";
import { Link, graphql } from 'gatsby'
import loadable from "@loadable/component";
import Layout from "../components/layout";
import SEO from "../components/seo"
import { homeValuation, instantValuation } from "../site/urls";
import Instagram from "../components/Instagram/Instagram"
const BreadcrumbModule = loadable(() => import("../components/BreadcrumbModule/BreadcrumbModule"));
const NewsTab = loadable(() => import("../components/NewsTab/NewsTab"));
// const InnerPagination = loadable(() => import("../components/InnerPagination/InnerPagination"));

const News = ({ data }, props) => {
  const PageData = data?.strapiPage
  const allNews = data?.allStrapiBlog.edges
  return (
    <Layout popularSearch={PageData?.select_popular_search?.title}>
      <div className="layout-padding-top">
      <div className="bottom-right-image-home">
        <div className="hover-block">
          <div className="block-elements">
           {/* <Link to={instantValuation}>Instant Online Valuation</Link> */}
           {/* <div className="vertical-line"></div> */}
           <Link to={homeValuation}>Home Visit Valuation</Link>
           </div>
        </div>
        <div className="banner-img"></div>
     </div>
        <SEO title={PageData?.seo?.metaTitle ? PageData?.seo?.metaTitle : PageData?.title} description={PageData?.seo?.metaDescription ? PageData?.seo?.metaDescription : PageData?.title} />
        <BreadcrumbModule choose_menu={PageData.choose_menu} pagename={PageData.title} />
        <div className="grey-bg">
          {PageData?.add_page_modules?.length > 0 && PageData.add_page_modules?.map((module, index) => {
            return (
              <>{module.__typename === "STRAPI__COMPONENT_PAGE_MODULES_GLOBAL_MODULE" && module.select_module === "blog" && <>
                <NewsTab title={PageData?.title} data={allNews} />
                {/* <InnerPagination /> */}
                </>
                }
               {module?.__typename ===
              "STRAPI__COMPONENT_PAGE_MODULES_GLOBAL_MODULE" &&
              module?.select_module === "instagram" && <Instagram {...module} />}
              </>
            )
          }
          )}
        </div>
      </div>
    </Layout>
  )
}

export default News


export const query = graphql`
query ($page_id: String) {
    strapiPage(id: {eq: $page_id}) {
      ...PageFragment
      add_page_modules {
        ... on STRAPI__COMPONENT_PAGE_MODULES_GLOBAL_MODULE {
          select_module
          __typename
          title
        }
      }
        
    }
    allStrapiBlog(filter: {publish: {eq: true}}, sort: {fields: date, order: ASC}) {
        edges {
          node {
            title
            slug
            strapi_id
            tile_image {
              url
            }
            imagetransforms{
              tile_image_Transforms
            }
            category {
                strapi_json_value
            }
            blog_content {
              ... on STRAPI__COMPONENT_COMPONENTS_CONTENT_BLOCK {
                id
                content {
                  data {
                    content
                  }
                }
              }
            }
            
          }
        }
    }
  }
`