/**
 * SEO component that queries for data with
 * Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.com/docs/how-to/querying-data/use-static-query/
 */

import * as React from "react"
import PropTypes from "prop-types"

import { useStaticQuery, graphql } from "gatsby"
import { Helmet } from "react-helmet"
import { useLocation } from "@reach/router"

function Seo({ description, lang, title,meta,image }) {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
            env
          }
        }
      }
    `
  )

  // const siteMeta = data.glstrapi.siteConfig.siteMetaData
  const siteMeta = "meta data"
  const metaTitle = title || siteMeta.title
  const metaDescription = description || siteMeta.description

  var { pathname } = useLocation()

  if (pathname == "/home/" || pathname == "/home") pathname = "/"

  var curentUrl = ""
  curentUrl = process.env.GATSBY_SITE_URL + pathname

  // check trailing slash
  if (!curentUrl.endsWith("/")) curentUrl = curentUrl + "/"

  // remove page- from url
  if (curentUrl.includes("page-")) {
    curentUrl = curentUrl
      .split("/")
      .filter(path => !path.includes("page-"))
      .join("/")
  }

  //remove multiple slashes
  curentUrl = curentUrl.replace(/([^:]\/)\/+/g, "$1")

  //set lowercase url
  curentUrl = curentUrl.toLowerCase()


  // let robots = "all"
  // if(site.siteMetadata?.env !== "production"){
  //   robots = "none"
  // }

  return (

    <Helmet
      htmlAttributes={{
        lang,
      }}
      link={[
        {
          href: curentUrl,
          rel: "canonical",
        },
      ]}
      title={metaTitle}
      titleTemplate={`%s | Oliver's Town Estate Agents`}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `og:title`,
          content: metaTitle,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:image`,
          content: image ? image : "",
        },
        {
          property: `og:url`,
          content: curentUrl,
        },
        {
          property: `og:image:secure_url`,
          content: image ? image : "",
        },
        {
          property: `og:image:width`,
          content: `400`,
        },
        {
          property: `og:image:height`,
          content: `300`,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:creator`,
          content: siteMeta?.author || ``,
        },
        {
          name: `twitter:title`,
          content: metaTitle,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
        {
          name: `facebook-domain-verification`,
          content: ``,
        },
      ].concat(meta)}
    />
    // <>
    //   <title>{defaultTitle ? `${title} | ${defaultTitle}` : title}</title>
    //   <meta name="robots" content={robots}></meta>
    //   <meta name="description" content={metaDescription} />
    //   <meta property="og:title" content={title} />
    //   <meta property="og:description" content={metaDescription} />
    //   <meta property="og:type" content="website" />
    //   <meta name="twitter:card" content="summary" />
    //   <meta name="twitter:creator" content={site.siteMetadata?.author || ``} />
    //   <meta name="twitter:title" content={title} />
    //   <meta name="twitter:description" content={metaDescription} />
    //   {children}
    // </>
  )
}


Seo.defaultProps = {
  lang: `en`,
  meta: [],
  description: ``,
  image: null,
}

Seo.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
  image: PropTypes.string,
}

export default Seo
